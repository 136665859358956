import React, { useContext, useRef, useCallback, useMemo, useEffect } from 'react'
import { motion, useAnimation, useMotionValue, useInView } from "framer-motion";
import useDimensions from "react-cool-dimensions";
import Box from '@mui/system/Box';
import useTheme from '@mui/system/useTheme';
import CardComplex from "../cardComplex"
import ProviderTripStepper, { ContextTripStepper } from "../contextTripStepper"
import { useBreakpoint } from '../contextBreakpointMediaQ';



const getCorrectedResponsiveAroundEllipse = ({ currentWidth, rx, widthAround, ry, heightAround }) => {
  const currWidth = 2 * rx + 2 * widthAround
  if (currentWidth === 0 || currentWidth === 1) return { rx, widthAround, ry, heightAround }
  if (currWidth < currentWidth) return { rx, widthAround, ry, heightAround }
  const coef = currentWidth / currWidth

  return { rx: Math.round(coef * rx), widthAround: Math.round(coef * widthAround), ry: Math.round(coef * ry), heightAround: Math.round(coef * heightAround) }

}


const styleSxWrap = ({ elHeightNoMargins, heightAround, ry, origWidthImage, imgWidth }) => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  mt: 0,
  mb: 0,
  mx: 0,
  pt: [ 5, 1, 2, 3 ],
  pb: [ 100, 100, 100, 100 ],
  px: 0,
  perspective: "1000px",
  borderRadius: "50%",

  "& .ellipse": {
    position: "absolute",
    boxShadow: 12,
    height: `${elHeightNoMargins}px`,
    width: `${elHeightNoMargins}px`,
    top: 0,
    background: (theme) => theme.gradients.lin3(0, theme.palette.primary2.main, "0%", theme.palette.primary2.light, "50%", theme.palette.primary2.main, "100%",),
    borderRadius: "50%",

    "& .wrapRotating": {
      position: "relative",
      bgcolor: "secondary2.main",
      zIndex: 2,
      top: `50%`,
      left: `50%`,
      width: `50%`,
      lineHeight: "initial",
      "& .rotating": {
        opacity: 0.5,
        position: "absolute",
        textAlign: "center",
        bgcolor: "secondary.dark",
        color: "secondary.contrastText",
        borderRadius: "50%",
        transformOrigin: "left",
        fontSize: "10px",
        width: "100%",
      },
    }
  },
  "& .wrapDivsAround": {
    position: "absolute",
    maxWidth: "100%",
    width: "100%",
    height: "100%",
    zIndex: 1,
    top: 0,
    "& .aroundItem": {
      opacity: 0.5,
      position: "absolute",
      bgcolor: "secondary2.main",
      height: heightAround,
      maxWidth: 'fit-content',
      width: imgWidth,
      lineHeight: "initial",
      "& .MuiTypography-root": {
        color: "secondary2.contrastText",
        fontSize: "16px",
      },
      "& .MuiCollapse-entered": {
        maxHeight: heightAround + ry,
      },
      "& .collapseRoot": {
        maxHeight: "none",
        bgcolor: "secondary.main",
        color: "secondary.contrastText",
      },
      "& .collapseWrap": {
        bgcolor: "primary2.main",
        color: "primary2.contrastText",
      },
      "& .collapseWrapInn": {
        bgcolor: "primary.main",
        color: "primary.contrastText",
      },
    }
  }
})



const varEllipse = (angle) => {
  return {
    initial: false,
    enter: {
      opacity: 1,
      transition: {
        duration: 0.1,
        staggerChildren: 0.2,

      },
    },
  }
};

const varRotating = {
  initial: false,
  enter: ({ angle }) => {
    return ({
      rotateZ: angle,
      opacity: 1,
      transition: {
        delay: 2,
        duration: 0.5,

      },
    })
  },
  whileHover: {
    cursor: "pointer",
    scaleY: 1.5,
    transition: {
      ease: 'circInOut',
      duration: 1,
    },
  },
  whileTap: {
    cursor: "pointer",
    scale: 0.8,
    transition: {
      ease: 'circInOut',
      duration: 1,
    },
  }


};

const varAroundItem = ({ iItem, left, top }) => {
  return {
    initial: false,
    enter: {
      x: left,
      y: top,
      opacity: 1,
      transition: {
        duration: 1,
        when: "beforeChildren",
        staggerChildren: 0.5,
      },
    },
    whileHover: {
    },
    whileTap: {
    },
  }
};

const varAroundImg = ({ iItem, left, top }) => {
  return {
    initial: false,
    enter: {
      rotateZ: 0,
      transition: {
        duration: 0.5,
      },
    },
    whileHover: {
      cursor: "pointer",
      rotateX: [ null, 90, 0 ],
      transition: {
        ease: 'circInOut',
        duration: 1,
      },
    },
    whileTap: {
      cursor: "pointer",
      scale: 0.8,
      rotateX: [ null, 90, 0 ],
      transition: {
        ease: 'circInOut',
        duration: 1,
      },
    },
  }
};


//======================================================================================



const RotatingItem = ({
  ry, rx,
  status,
  nameItem,
  iItem,
  positions,
  positionSpecial = 1,
  angleStep,
  styleWrapRotating,
  styleRotating,
  delayRotation,
  intervalRotation,
  angleM,
  isInView
}) => {

  const controlsRotating = useAnimation();
  const theme = useTheme()
  const refPosSpecial = useRef(0)
  const numberPos = positions.length
  const firstAngle = positions[ iItem ].angle
  refPosSpecial.current = positionSpecial
  const bgColorReverse = theme.palette.secondary.light

  const specialReverseTransformR = useMemo(() => ({
    backgroundColor: bgColorReverse,
    fontSize: "16px",
    transition: {
      repeat: 1,
      repeatType: "reverse", duration: 3,
    }
  }), [ bgColorReverse ])



  React.useEffect(() => {
    if (!isInView) {
      controlsRotating.stop()
      return
    }
    controlsRotating.start({
      rotateZ: firstAngle,
      opacity: 1,
      transition: {
        delay: 2,
        duration: 1
      }
    })
    let ticks = 0
    const rotateOnMotionAngleR = latest => {
      ticks = ticks + 1
      const iNext = (iItem + ticks) % numberPos

      const isSpecial = iNext === positionSpecial
      const specialTr = isSpecial ? specialReverseTransformR : {}
      controlsRotating.start({
        rotateZ: firstAngle + latest,
        transition: { duration: 1 }
      })
      isSpecial && controlsRotating.start(specialTr)

    }
    const unsubscribeAngleM = angleM.onChange(rotateOnMotionAngleR)
    return () => {
      controlsRotating.stop()
      unsubscribeAngleM()
    }
  }, [ controlsRotating, numberPos, positionSpecial, firstAngle, specialReverseTransformR, angleM, iItem, isInView ])

  return (
    <Box component="div"
      className="wrapRotating"
      style={styleWrapRotating}
      key={`wrapRotating-${iItem}`}
    >
      <motion.div
        variants={varRotating}
        initial="initial"
        animate={controlsRotating}
        whileHover="whileHover"
        whileTap="whileTap"
        custom={{ angle: firstAngle }}
        className="rotating"
        style={styleRotating}
        key={`rotating-${iItem}-${nameItem}`}
      >
        {nameItem}
      </motion.div>
    </Box>
  )

}


const durationSpecialReverse = 0.5
const durationSpecialMoveUp = 1
const durationSpecialMoveDown = 0.5
const durationPureRotating = 1
const durationCardCollapsing = 0.5

//=========================================================================================================



const specialReverseTransformAround =
  (() => {
    return ({
      rotateX: 90,
      transition: {
        repeat: 1,
        repeatType: "reverse", duration: durationSpecialReverse
      }
    })
  })


const AroundItem = (
  {
    heightAround, ry,
    iItem,
    styleCollapse,
    nameItem,
    optionsImg,
    imgWidth,
    optionsCard,
    positionSpecial = 1,
    styleAroundItem,
    positions,
    delayRotation,
    intervalRotation,
    topPosition,
    downPosition,
    origWidth,
    origWidthImage,
    angleM,
    isInView,
  }
) => {


  const lastCollapsingTimeout = intervalRotation - 500 - durationCardCollapsing * 1000
  const timeout0 = useMemo(() => lastCollapsingTimeout - 9500 - 1000 * durationSpecialMoveDown - durationCardCollapsing * 1000, [ lastCollapsingTimeout ])
  const timeout1 = useMemo(() => lastCollapsingTimeout - 7500 - 1000 * durationSpecialMoveDown - durationCardCollapsing * 1000, [ lastCollapsingTimeout ])
  const timeout2 = useMemo(() => lastCollapsingTimeout - 5500 - 1000 * durationSpecialMoveDown - durationCardCollapsing * 1000, [ lastCollapsingTimeout ])
  const timeout3 = useMemo(() => lastCollapsingTimeout - 3700 - 1000 * durationSpecialMoveDown - durationCardCollapsing * 1000, [ lastCollapsingTimeout ])
  const timeout4 = useMemo(() => lastCollapsingTimeout - 2000 - 1000 * durationSpecialMoveDown, [ lastCollapsingTimeout ])
  const isMounted = useRef(false);

  const [ isCard, setIsCard ] = React.useState(false)
  const refIsCard = useRef(isCard)
  refIsCard.current = isCard

  const [ cardExpanded, setCardExpanded ] = React.useState(false);
  const refCardExpanded = useRef(cardExpanded)
  refCardExpanded.current = cardExpanded
  const handleCardExpandClick = () => {
    setCardExpanded((prev) => !prev);
  };

  const [ activeStep, setActiveStep ] = useContext(ContextTripStepper);
  const refCard = React.useRef();
  const refCardCollapse = React.useRef();
  const refMultiple = { refCard, refCardCollapse }

  const controlsAround = useAnimation();
  const refPosSpecialA = useRef(0)
  refPosSpecialA.current = positionSpecial
  const numberPos = positions.length
  const { left, top } = positions[ iItem ]
  const yMiddle = Math.round((downPosition - topPosition) / 2)
  const specialMoveUpTransform = useCallback(({ pos }) => {
    const shiftUp = pos === 1 ? { y: topPosition, x: downPosition } : { y: downPosition, x: 0 }
    return {
      ...shiftUp,
      opacity: 1,
      width: 'min-content',
      minWidth: "400px",
      transition: {
        duration: durationSpecialMoveUp
      }
    }
  }, [ topPosition, downPosition ])

  const specialMoveDownTransform = useCallback(
    (pos => {
      const shiftDown = pos === 1 ? { y: yMiddle, x: downPosition } : { y: downPosition, x: yMiddle }
      return {
        ...shiftDown,
        transition: {
          duration: durationSpecialMoveDown,
        },
      }
    }), [ yMiddle, downPosition ])

  const notSpecialTransform = useCallback(() => ({
    opacity: 0.5,
    width: `${origWidth}px`,
    minWidth: "0px",
    transition: {
      duration: 1
    }
  }), [ origWidth ])

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    }
  }, [])

  useEffect(() => {
    if (window) {
      if (!isInView) {
        controlsAround.stop()
        setIsCard(false)
        setCardExpanded(false)
        return
      }
      controlsAround.start({
        x: left,
        y: top,
        opacity: 1,
        transition: {
          duration: 0.5,
          when: "beforeChildren",
          staggerChildren: 0.5,
        },
      })
      const timeIds = {}

      let ticks = 0

      const rotateOnMotionAngleA = async (latest) => {
        ticks = ticks + 1
        const iNext = (iItem + ticks) % numberPos
        const isSpecial = iNext === positionSpecial
        const { top, left, } = positions[ iNext ] // angle, width, height 

        isMounted.current && await controlsAround.start({
          y: top,
          x: left,
          transition: { duration: durationPureRotating }
        })

        if (isSpecial) {
          refCardExpanded.current = false
          isMounted.current && setCardExpanded(false)
          isMounted.current && setIsCard(true)

          const hasTripActiveSteps = nameItem === "Make Your Trip" ? true : false

          isMounted.current && hasTripActiveSteps === true && setActiveStep(0)
          timeIds.timeout0 = window.setTimeout(() => {
            if (isMounted.current) {
              refCardExpanded.current = true
              setCardExpanded(true)
            }
          }, timeout0)

          timeIds.timeout1 = hasTripActiveSteps === true ? window.setTimeout(() => {
            isMounted.current && setActiveStep(1);
          }, timeout1) : null
          timeIds.timeout2 = hasTripActiveSteps === true ? window.setTimeout(() => {
            isMounted.current && setActiveStep(2);
          }, timeout2) : null
          timeIds.timeout3 = window.setTimeout(() => {
            if (isMounted.current) {
              refCardExpanded.current = false
              setCardExpanded(false)
            }
          }, timeout3)
          timeIds.timeout4 = window.setTimeout(() => {
            isMounted.current && controlsAround.start(specialMoveDownTransform(positionSpecial,)) // refPosSpecialA.current
          }, timeout4)

          timeIds.timeout5 = window.setTimeout(() => {
            isMounted.current && setIsCard(false)
          }, lastCollapsingTimeout)

          isMounted.current && await controlsAround.start(specialReverseTransformAround)
          isMounted.current && await controlsAround.start(specialMoveUpTransform({ pos: positionSpecial })) // refPosSpecialA.current
        } else {
          isMounted.current && controlsAround.start(notSpecialTransform)
        }
      }

      const unsubscribeAngleMA = angleM.onChange(rotateOnMotionAngleA)

      return () => {
        controlsAround.stop()
        clearTimeout(timeIds.timeout5)
        clearTimeout(timeIds.timeout4)
        clearTimeout(timeIds.timeout3)
        clearTimeout(timeIds.timeout2)
        clearTimeout(timeIds.timeout1)
        clearTimeout(timeIds.timeout0)
        unsubscribeAngleMA()
      }
    }
  }, [ isInView, top, left, positions, lastCollapsingTimeout, timeout0, timeout1, timeout2, timeout3, timeout4, positionSpecial, controlsAround, iItem, numberPos, notSpecialTransform, specialMoveDownTransform, specialMoveUpTransform, nameItem, setActiveStep, angleM ])


  const { imgName, imgSrc, } = optionsImg


  return (
    <Box component={motion.div}
      variants={varAroundItem({ iItem, left, top })}
      initial="initial"
      animate={controlsAround}
      whileHover="whileHover"
      whileTap="whileTap"
      className="aroundItem"
      style={styleAroundItem}
      key={`divs-one-around-${iItem}`}
    >
      {!!isCard ? (
        <CardComplex
          ref={refMultiple}
          handleExpandedParent={handleCardExpandClick}
          expanded={cardExpanded}
          data={optionsCard}
          optionsImg={optionsImg}
          key={`${nameItem}-card-${iItem}`}
          styleCollapse={styleCollapse}
          classNameCollapseRoot="collapseRoot"
          classNameCollapseWrap="collapseWrap"
          classNameCollapseWrapInn="collapseWrapInn"
          durationCardCollapsing={durationCardCollapsing}
        >
        </CardComplex>)
        : (
          <motion.img
            src={imgSrc}
            style={{ rotateZ: 10, width: `${imgWidth}px` }}
            alt={imgName}
            variants={varAroundImg({ iItem, left, top })}
            initial="initial"
            animate="enter"
            whileHover="whileHover"
            whileTap="whileTap"
            key={`varAroundImg-${iItem}-${imgName}`}
          />
        )}
    </Box>
  )

}




//========================================================================================================

const rx1 = 200
const ry1 = 200
const offset = 0
const widthAround1 = 200
const heightAround1 = 112
const cw = true
const sizesIntoStyle = false
const styleEllipse = {}
const styleDivCont = {}
const positionRight = 1
const positionMobile = 2
const savePlaceLeft = true


const DivsAroundEllipseMotionShowingOne = (({ arrData, intervalRotation = 15000, delayRotation = 1000, imgWidth = 200, origWidthImage = 600 }) => {
  const isMounted = useRef(false);
  const ref = useRef(null);
  // , height, entry 
  const { observe, width } = useDimensions({
    onResize: ({ observe, unobserve, width, height, entry }) => {
      // Triggered whenever the size of the target is changed...

      unobserve(); // To stop observing the current target element
      observe(); // To re-start observing the current target element
    },
  })

  const currentWidth = Math.round(width)

  const isInView = useInView(ref)

  const deviceSize = useBreakpoint()
  const angleM = useMotionValue(0)

  const { rx, widthAround, ry, heightAround } = getCorrectedResponsiveAroundEllipse({ currentWidth, rx: rx1, widthAround: widthAround1, ry: ry1, heightAround: heightAround1 })
  let positionSpecial

  if (deviceSize === "xs" || deviceSize === "sm") {
    positionSpecial = positionMobile
  } else {
    positionSpecial = positionRight
  }
  const numberPos = arrData.length
  const angleStep = 360 / numberPos
  const angleStart = 360 - angleStep
  const elHeightNoMargins = ry * 2

  const styleAroundWidthHeightLine = sizesIntoStyle ? ({
    width: `${widthAround}px`,
    height: `${heightAround}px`,
  }) : {}

  const styleWrapItem = {
    height: savePlaceLeft === true ? `${elHeightNoMargins + 2 * heightAround}px` : `${elHeightNoMargins + heightAround}px`
  }

  let marginLeftShift = 0
  if (savePlaceLeft) {
    marginLeftShift = 0
    styleWrapItem.alignItems = "flex-start"
    styleEllipse.marginLeft = 0
    styleEllipse.marginRight = 0
  }
  const topPositionArround = savePlaceLeft === true ? 0 : -heightAround
  const downPositionArround = 2 * ry
  const positions = []
  const divsRotating = []
  const divsAroundComp = []
  for (var iItem = 0; iItem < numberPos; iItem++) {

    const { styleCollapse, nameItem, optionsImg, optionsCard } = arrData[ iItem ]
    const top1 = Math.round(ry + -ry * Math.cos((360 / numberPos / 180) * (iItem + offset) * Math.PI))
    const left1 = Math.round(rx + rx * (cw ? Math.sin((360 / numberPos / 180) * (iItem + offset) * Math.PI) : -Math.sin((360 / numberPos / 180) * (iItem + offset) * Math.PI)))

    const styleWrapRotating = {
      ...styleDivCont,
    }


    const styleRotating = {
      ...styleDivCont,
    }

    let left, top
    if (savePlaceLeft) {
      left = left1 < rx ? 0 + marginLeftShift : left1 + marginLeftShift
      top = top1
    } else {
      left = left1 < rx ? left1 - widthAround + marginLeftShift : left1 + marginLeftShift
      top = top1 < ry ? top1 - heightAround : top1
    }
    const styleAroundItem = {
      ...styleAroundWidthHeightLine,
      ...styleDivCont,
      height: "auto",
    }

    const angle = Math.round(angleStart + angleStep * (iItem + offset))

    positions[ iItem ] = {
      angle: angle,
      top: top,
      left: left,
      width: widthAround,
      height: heightAround,
    }


    divsRotating.push((
      <RotatingItem
        isInView={isInView}
        ry={ry}
        rx={rx}
        nameItem={nameItem}
        iItem={iItem}
        positions={positions}
        positionSpecial={positionSpecial}
        angleStep={angleStep}
        styleWrapRotating={styleWrapRotating}
        styleRotating={styleRotating}
        delayRotation={delayRotation}
        intervalRotation={intervalRotation}
        angleM={angleM}
        key={`rotating-${iItem}`}
      />
    ))

    divsAroundComp.push((
      <AroundItem
        isInView={isInView}
        heightAround={heightAround}
        ry={ry}
        iItem={iItem}
        styleCollapse={styleCollapse}
        nameItem={nameItem}
        optionsImg={optionsImg}
        imgWidth={imgWidth}
        optionsCard={optionsCard}
        positionSpecial={positionSpecial}
        styleAroundItem={styleAroundItem}
        positions={positions}
        delayRotation={delayRotation}
        intervalRotation={intervalRotation}
        topPosition={topPositionArround}
        downPosition={downPositionArround}
        origWidth={widthAround}
        origWidthImage={origWidthImage}
        angleM={angleM}
        key={`arround-${nameItem}-${iItem}`}
      />
    ))

  }
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    }
  }, [])

  useEffect(() => {
    if (window) {
      if (!isInView) return
      const rotateWrap = () => {
        angleM.set(angleM.get() + angleStep)
      }

      let defIntervalRotation
      rotateWrap()

      const timer = window.setTimeout(() => {
        if (isMounted.current) {
          defIntervalRotation = window.setInterval(rotateWrap, intervalRotation);
        }
      }, delayRotation)
      return () => {
        clearInterval(defIntervalRotation)
        defIntervalRotation = null
        clearTimeout(timer)
        angleM.set(0)
        // angleM.set(angleM.get())
      }
    }

  }, [ angleStep, intervalRotation, delayRotation, angleM, isInView ]);



  return positionSpecial === 1 ? (
    <Box component="div"
      className="wrapItem"
      sx={styleSxWrap({ elHeightNoMargins, heightAround, ry, origWidthImage, imgWidth })}
      style={styleWrapItem}
      ref={(el) => {
        observe(el); // Set the target element for measuring
        ref.current = el; // Share the element for other purposes
      }}
    >
      <motion.div
        variants={varEllipse(0)}
        initial="initial"
        animate="enter"
        whileHover="whileHover"
        whileTap="whileTap"
        className="ellipse"
        style={styleEllipse}
        key="varDAEMSO-varEllipse-rot"
      >
        {divsRotating}
      </motion.div>
      <motion.div
        className="wrapDivsAround"
        variants={varEllipse(0)}
        initial="initial"
        animate="enter"
        whileHover="whileHover"
        whileTap="whileTap"
        key="varDAEMSO-wrapDivsAround"
      >
        <ProviderTripStepper key={`providerTrip-arround1`}>
          {divsAroundComp}
        </ProviderTripStepper>

      </motion.div>
    </Box>
  ) : (
    <Box component="div"
      className="wrapItem"
      sx={styleSxWrap({ elHeightNoMargins, heightAround, ry, origWidthImage, imgWidth })}
      style={styleWrapItem}
      ref={(el) => {
        observe(el); // Set the target element for measuring
        ref.current = el; // Share the element for other purposes
      }}
    >

      <motion.div
        variants={varEllipse(0)}
        initial="initial"
        animate="enter"
        whileHover="whileHover"
        whileTap="whileTap"
        className="ellipse"
        style={styleEllipse}
        key="varDAEMSO-varEllipse-rot"
      >
        {divsRotating}
      </motion.div>
      <motion.div
        className="wrapDivsAround"
        variants={varEllipse(0)}
        initial="initial"
        animate="enter"
        whileHover="whileHover"
        whileTap="whileTap"
        key="varDAEMSO-wrapDivsAround"
      >
        {<ProviderTripStepper key={`providerTrip-arround`}>
          {divsAroundComp}
        </ProviderTripStepper>
        }
      </motion.div>

    </Box>
  )
})

export default DivsAroundEllipseMotionShowingOne
