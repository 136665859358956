import React from 'react';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import StarRateIcon from '@mui/icons-material/StarRate';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import RowingIcon from '@mui/icons-material/Rowing';
import useHotelImagesW600 from "../../_shared/useHotelImagesW600"

import DivsAroundEllipseMotionShowingOne from "../../_shared/shaping/DivsAroundEllipseMotionShowingOne"

const dataCards = [ {
    name: "Hotel-rooms",
    cardHeader: {
        avatarFaceName: [ 1, 2, 3 ],
        avatarChildren: [ "B", "E", "S", "T", <StarRateIcon key="starAvat" /> ],
        title: "The Rooms",
        subheader: "November 21, 2018",
    },
    cardMedia: {
        imageName: "hotel-rooms",
        title: "Hotel rooms"
    },
    cardContent: {
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    collapse: {
        avatarFaceName: [ 3, 2, 6 ],
        avatarChildren: "R",
        children: <>
            <Typography paragraph>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Urna duis convallis convallis tellus id interdum velit laoreet id. Faucibus in ornare quam viverra orci sagittis eu. Arcu dictum varius duis at. Est sit amet facilisis magna etiam tempor orci eu lobortis. Pulvinar mattis nunc sed blandit libero volutpat sed cras. Vitae tortor condimentum lacinia quis vel eros donec ac. Sodales neque sodales ut etiam sit amet nisl. Ac placerat vestibulum lectus mauris ultrices eros. Amet facilisis magna etiam tempor. Enim tortor at auctor urna nunc id.
            </Typography>
            <Typography paragraph>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vel fringilla est ullamcorper eget nulla facilisi etiam dignissim. Eu feugiat pretium nibh ipsum consequat nisl vel pretium lectus. Tincidunt dui ut ornare lectus sit. Morbi enim nunc faucibus a. Dolor sit amet consectetur adipiscing elit ut aliquam purus. Risus nec feugiat in fermentum posuere urna. Tristique senectus et netus et malesuada. Vel quam elementum pulvinar etiam non quam lacus suspendisse. Auctor augue mauris augue neque gravida in fermentum et sollicitudin. Nunc eget lorem dolor sed viverra ipsum nunc aliquet bibendum. Hac habitasse platea dictumst vestibulum. Amet purus gravida quis blandit. Ultrices eros in cursus turpis massa tincidunt dui ut.
            </Typography>
            <List dense disablePadding={true}>
                <ListItem key="li1" button>
                    <ListItemAvatar>
                        <Avatar>
                            <ImageIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae."
                    />
                </ListItem>
                <ListItem key="li2" button>
                    <ListItemAvatar>
                        <Avatar>
                            <WorkIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tortor aliquam nulla facilisi cras fermentum odio eu feugiat."
                    />
                </ListItem>
                <ListItem key="li3" button>
                    <ListItemAvatar>
                        <Avatar>
                            <BeachAccessIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Placerat duis ultricies lacus sed turpis tincidunt id aliquet."
                    />
                </ListItem>
            </List>
            <Typography paragraph>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ac tincidunt vitae semper quis lectus nulla at. A cras semper auctor neque vitae tempus.
            </Typography>
            <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ullamcorper velit sed ullamcorper morbi tincidunt ornare massa. Mi proin sed libero enim sed faucibus turpis. Augue lacus viverra vitae congue eu.
            </Typography>
        </>
    }
},
{
    name: "Bedroom",
    cardHeader: {
        avatarFaceName: 4,
        avatarChildren: "A",
        title: "Bedroom",
        subheader: "September 14, 2016",
    },
    cardMedia: {
        imageName: "bedroom",
        title: "Double room"
    },
    cardContent: {
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu mi bibendum neque egestas. Fringilla ut morbi tincidunt augue interdum velit.",
    },
    collapse: {
        children: <>
            <Typography paragraph>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam id diam maecenas ultricies mi eget. Vitae elementum curabitur vitae nunc sed. Consectetur libero id faucibus nisl. Amet consectetur adipiscing elit duis. Neque sodales ut etiam sit. Malesuada nunc vel risus commodo viverra maecenas. Risus viverra adipiscing at in tellus integer feugiat. Amet nisl suscipit adipiscing bibendum est ultricies integer. Duis ultricies lacus sed turpis tincidunt id aliquet risus feugiat. Amet luctus venenatis lectus magna fringilla. Quisque id diam vel quam elementum. Nunc lobortis mattis aliquam faucibus. Nunc faucibus a pellentesque sit amet porttitor. Semper quis lectus nulla at volutpat diam ut venenatis tellus. Tristique nulla aliquet enim tortor at auctor urna. Ultricies mi eget mauris pharetra et ultrices neque. In aliquam sem fringilla ut morbi tincidunt augue. Auctor urna nunc id cursus metus aliquam eleifend mi.
            </Typography>
            <Typography paragraph>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sem nulla pharetra diam sit amet nisl suscipit. Adipiscing elit duis tristique sollicitudin nibh.
            </Typography>
            <Typography paragraph>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Enim sed faucibus turpis in eu mi bibendum neque. Morbi tincidunt ornare massa eget egestas.
            </Typography>
            <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Augue lacus viverra vitae congue eu consequat ac felis donec. In est ante in nibh mauris cursus mattis.
            </Typography>
        </>
    }
},
{
    name: "Hotel at the Sea",
    cardHeader: {
        avatarFaceName: 5,
        avatarChildren: [ "H", <DoneAllIcon key="trip-done" /> ],
        title: "Hotel at the Sea",
        subheader: "September 14, 2016",
    },
    cardMedia: {
        imageName: "room",
        title: "Hotel at the Sea"
    },
    cardContent: {
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Diam donec adipiscing tristique risus nec feugiat in fermentum posuere. Nunc eget lorem dolor sed viverra ipsum. Magna fringilla urna porttitor rhoncus dolor purus non enim. Rhoncus dolor purus non enim praesent. Tortor dignissim convallis aenean et tortor. Viverra tellus in hac habitasse platea dictumst vestibulum rhoncus est. Ut enim blandit volutpat maecenas volutpat blandit aliquam etiam erat. Integer quis auctor elit sed vulputate mi. Proin sed libero enim sed faucibus turpis in. Felis bibendum ut tristique et egestas quis. Posuere morbi leo urna molestie at elementum. Volutpat consequat mauris nunc congue nisi vitae suscipit tellus. Amet purus gravida quis blandit turpis cursus. Ullamcorper velit sed ullamcorper morbi tincidunt.",
    },
    collapse: {
        avatarFaceName: 1,
        avatarChildren: "N",
        hotelTimeline: true,
        children: <>
            <Typography paragraph>
                lorem ipsum dolor sit amet consectetur adipiscing
            </Typography>
            <Typography paragraph>
                et tortor consequat id porta nibh venenatis
            </Typography>
            <Typography paragraph>
                facilisi etiam dignissim diam quis enim lobortis
            </Typography>
            <Typography>
                arcu cursus euismod quis viverra nibh cras
            </Typography>
            <Typography>
                purus in mollis nunc sed id
            </Typography>
        </>
    }
},
{
    name: "Make Your Trip",
    cardHeader: {
        avatarFaceName: [ 5, 2 ],
        avatarChildren: [ <DirectionsBoatIcon key="boat" />, <RowingIcon key="rowing" />, "H" ],
        title: "Make Your Trip",
        subheader: "June 14, 2021",
    },
    cardMedia: {
        imageName: "hotel",
        title: "Make Your Trip"
    },
    cardContent: {
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Et ligula ullamcorper malesuada proin libero nunc consequat interdum varius. Ultricies mi quis hendrerit dolor magna. Malesuada fames ac turpis egestas maecenas pharetra. Cursus mattis molestie a iaculis at erat pellentesque. Vitae justo eget magna fermentum. Arcu odio ut sem nulla pharetra diam. Commodo viverra maecenas accumsan lacus vel facilisis volutpat. In hac habitasse platea dictumst quisque. Enim blandit volutpat maecenas volutpat blandit aliquam. Maecenas accumsan lacus vel facilisis volutpat. Eu volutpat odio facilisis mauris sit amet. Urna cursus eget nunc scelerisque.",
    },
    collapse: {
        avatarFaceName: 3,
        avatarChildren: "C",
        steppersTrip: true,
        children: <>
            <Typography paragraph>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </Typography>
            <Typography paragraph>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </Typography>
            <Typography paragraph>
                nunc sed velit dignissim sodales ut eu
            </Typography>
            <Typography>
                tincidunt praesent semper feugiat nibh sed pulvinar
            </Typography>
            <Typography>
                amet cursus sit amet dictum sit amet
            </Typography>
        </>
    }
},
]

const imgWidth = 200

const HotelCardsAroundEllipseMotionWithData = () => {
    const hotelImagesFiles = useHotelImagesW600();
    const imagesNodes = hotelImagesFiles.nodes

    const arrData = dataCards.map((dataCard, i) => {
        const imgName = dataCard.cardMedia.imageName
        const imgNode = imagesNodes.find(imgNode => imgNode.name === imgName)
        const image = imgNode.childImageSharp.gatsbyImageData.images
        const optionsImg = { imgName, imgSrc: image.fallback.src }
        const optionsCard = { ...dataCard }
        const styleCollapse = {}

        return {
            styleCollapse: styleCollapse,
            nameItem: dataCard.name,
            optionsImg: optionsImg,
            optionsCard: optionsCard,
        }
    })

    return (
        <DivsAroundEllipseMotionShowingOne
            arrData={arrData}
            intervalRotation={18000}
            delayRotation={500}
            imgWidth={imgWidth}
            origWidthImage={600}
        />
    )
}
export default React.memo(HotelCardsAroundEllipseMotionWithData)

