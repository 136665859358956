import React from 'react';
import { motion } from "framer-motion";

import useTheme from '@mui/material/styles/useTheme';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import styled from '@mui/material/styles/styled';
import AvatarFaceByImgName from "./avatarFaceByImgName"
import HotelTimeline from "../pagesContents/animacie/hotelTimeline"
import SteppersTrip from "../pagesContents/animacie/steppersTrip"

const styleCard = {
  position: "absolute",
  border: "1px solid #ccc",
  background: "transparent",
  boxShadow: 5,
  height: "inherit",
  width: "inherit",
  px: 0,
  pt: 0,
  pb: [ 0, 1, 1, 2, 3 ],
  "& img": {
    width: "100%"
  }
}

const styleSvgIconRoot = {
  fill: "currentColor",
  width: "1em",
  height: "1em",
  display: "inline-block",
  fontSize: "1.5rem",
  flexShrink: 0,
  userSelect: "none",
}


const StyledMotionDivRoot = styled(motion.div)({
  opacity: 0.9,
  backgroundColor: "red",
  "&.MuiCard-root": {
    overflow: "hidden",
  },
  "& .MuiCardHeader-root": {
    display: "flex",
    padding: "16px",
    alignItems: "center",
    "& .MuiCardHeader-content": {
      flex: "1 1 auto",
    },
    "& .MuiCardHeader-action": {
      flex: "0 0 auto",
      alignSelf: "flex-start",
      marginTop: "-8px",
      marginRight: "-8px",
    }
  },
  "& .MuiCardHeader-avatar": {
    flex: "0 0 auto",
    marginRight: "16px"
  },
  "& .MuiAvatar-root": {

    width: "40px",
    height: "40px",
    display: "flex",
    overflow: "hidden",
    position: "relative",
    fontSize: "1.25rem",
    alignItems: "center",
    flexShrink: 0,
    lineHeight: 1,
    userSelect: "none",
    borderRadius: "50%",
    justifyContent: "center",

    "& .MuiAvatar-img": {
      color: "transparent",
      width: "100%",
      height: "100%",
      objectFit: "cover",
      textAlign: "center",
      textIndent: "10000px",
    }
  },
  "& .MuiCardMedia-root": {
    display: "block",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    fontSize: "24px",
    color: "white"
  },
  "& .MuiCardContent-root": {
    padding: "16px",
  },
  "& .MuiCardActions-root": {
    display: "flex",
    padding: "8px",
    alignItems: "center",
  },

})

const StyledMotionDivHeader = styled(motion.div)(({ theme }) => ({
  backgroundColor: theme.palette.primary2.main,
  color: theme.palette.primary2.contrastText,
}))


const StyledMotionDivSpanAvatars = styled(motion.div)({
  display: "flex",
  opacity: 0.5,
  "&.MuiCardHeader-avatar": {
    flex: "0 0 auto",
    marginRight: "16px"
  },
})

const StyledMotionDivHeaderContent = styled(motion.div)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}))

const StyledMotionDivHeaderTitle = styled(motion.div)(({ theme }) => ({
  opacity: 0.5,
  backgroundColor: theme.palette.primary2.main,
  color: theme.palette.primary2.contrastText,
}))

const StyledMotionDivHeaderSubtitle = styled(motion.div)(({ theme }) => ({
}))

const StyledMotionButtonHeaderActionButton = styled(motion.button)(({ theme }) => ({
  flex: "0 0 auto",
  padding: "12px",
  overflow: "visible",
  textAlign: "center",
  borderRadius: "50%",
  backgroundColor: theme.palette.info.main,
  color: theme.palette.info.contrastText,
}))

const StyledMotionSpanHeaderActionButtonSpan = styled(motion.span)(({ theme }) => ({
  backgroundColor: theme.palette.info.main,
  color: theme.palette.info.contrastText,
}))

const StyledMotionPathHeaderActionButtonIconPath = styled(motion.path)(({ theme }) => ({
  pathLength: 0,
  pathSpacing: 0.0,
  fillOpacity: 0,
  stroke: theme.palette.secondary2.dark,
  fill: theme.palette.secondary.main,
}))

const StyledMotionPContentText = styled(motion.p)(({ theme }) => ({
  backgroundColor: theme.palette.warning.main,
  color: theme.palette.warning.contrastText,
}))

const StyledMotionDivActions = styled(motion.div)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}))


const StyledMotionButtonActionsButton = styled(motion.button)(({ theme }) => ({
  backgroundColor: theme.palette.primary2.main,
  color: theme.palette.primary2.contrastText,
}))

const StyledMotionButtonActionsButtonWithExpanded = styled(StyledMotionButtonActionsButton)(({ theme }) => ({
  transform: 'rotate(0deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

const StyledMotionSpanActionsButtonSpan = styled(motion.span)(({ theme }) => ({
  backgroundColor: theme.palette.primary2.main,
  color: theme.palette.primary2.contrastText,
}))

const StyledMotionPathActionsButtonSpanPath = styled(motion.path)(({ theme }) => ({
  pathLength: 0,
  pathSpacing: 0,
  fillOpacity: 0,
  stroke: theme.palette.secondary2.dark,
  fill: theme.palette.secondary.main,
  // stroke: "blue",
}))

const StyledMotionSpanSpanAvatars = styled(motion.span)({
  display: "flex",
  opacity: 0.5,
  "&.MuiCardHeader-avatar": {
    flex: "0 0 auto",
    marginRight: "16px"
  },
})


const StyledMotionDivAvatar = styled(motion.div)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  "&:nth-child(2n)": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  "&:nth-child(3n)": {
    backgroundColor: theme.palette.primary2.main,
    color: theme.palette.primary2.contrastText,
  },
  "&:nth-child(4n)": {
    backgroundColor: theme.palette.secondary2.main,
    color: theme.palette.secondary2.contrastText,
  },
  "&:nth-child(5n)": {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
}))



const varCard = ({ palette }) => {
  return {
    initial: false,
    enter: {
      opacity: 1,
      transition: {
        delayChildren: 0.5,
        staggerChildren: 0.5,
        duration: 0.2,
        when: "beforeChildren",
      },

    },
    whileHover: {
      rotateX: 90,
      scale: 1.2,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        staggerChildren: 0.3,
        duration: 0.3,
        when: "afterChildren"
      }
    },
    whileTap: {
      rotateX: 90,
      scale: 0.8,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        staggerChildren: 0.3,
        duration: 0.3,
        when: "afterChildren"
      }
    }
  }
}

const varHeader = ({ palette }) => {
  return {
    initial: false,
    enter: {
      opacity: 1,
      transition: {
        staggerChildren: 0.9,
        duration: 0.3,
        when: "beforeChildren",
      },
    },
    whileHover: {
      rotateY: 90,
      scale: 1.2,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        duration: 0.5
      }
    },
    whileTap: {
      rotateY: 90,
      scale: 0.8,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        duration: 0.5
      }
    },
  }
}

const varSpanAvatars = ({ palette }) => {
  return {
    initial: false,
    enter: {
      rotateX: [ 90, 0 ],
      opacity: 1,
      transition: {
        staggerChildren: 0.9,
        duration: 0.3,
        when: "beforeChildren",
      },

    },
    collapsed: {
      opacity: 0.5,
      transition: {
        duration: 0.1,
      },
    },
    whileHover: {
      rotateY: 90,
      scale: 1.2,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        duration: 0.5
      }
    },
    whileTap: {
      rotateY: 90,
      scale: 0.8,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        duration: 0.5
      }
    },
  }
}

const varAvatarCh = ({ i, palette }) => {
  return {
    initial: false,
    enter: {
      opacity: 1,
      rotateZ: [ 180, 0 ],
      transition: {
        duration: 0.4,
        when: "afterChildren",
      },

    },

    exit: {
      rotateZ: 180,
      transition: {
        duration: 0.1,
      },
    },
    whileHover: {
      rotateY: 90,
      scale: 1.2,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        duration: 0.5
      }
    },
    whileTap: {
      rotateY: 90,
      scale: 0.8,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        duration: 0.5
      }
    },
  }
}

const varHeaderContent = ({ palette }) => {
  return {
    initial: false,
    enter: {
      y: 0,
      opacity: 1,
      transition: {
        staggerChildren: 0.5,
        duration: 0.4,
        when: "beforeChildren",
      },
    },
    whileHover: {
      rotateY: 90,
      scale: 1.2,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        duration: 0.5
      }
    },
    whileTap: {
      rotateY: 90,
      scale: 0.8,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        duration: 0.5
      }
    },
  }
}

const varHeaderSpanTitle = ({ palette }) => {
  return {
    initial: false,
    enter: {
      x: 0,
      opacity: 1,
      transition: {
        staggerChildren: 0.5,
        duration: 0.4,
        when: "beforeChildren",
      },
    },
    whileHover: {
      rotateY: 90,
      scale: 1.2,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        duration: 0.5
      }
    },
    whileTap: {
      rotateY: 90,
      scale: 0.8,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        duration: 0.5
      }
    },
  }
}

const varHeaderSpanSubtitle = ({ palette }) => {
  return {
    initial: false,
    enter: {
      x: 0,
      opacity: 1,
      transition: {
        staggerChildren: 0.5,
        duration: 0.4,
        when: "beforeChildren",
      },
    },
    whileHover: {
      rotateY: 90,
      scale: 1.2,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        duration: 0.5
      }
    },
    whileTap: {
      rotateY: 90,
      scale: 0.8,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        duration: 0.5
      }
    },
  }
}

const varHeaderAction = ({ palette }) => {
  return {
    initial: false,
    enter: {
      rotateZ: 0,
      transition: {
        staggerChildren: 0.5,
        duration: 0.4,
        when: "afterChildren",
      },
    },
    whileHover: {
      rotateY: 90,
      scale: 1.2,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        duration: 0.5
      }
    },
    whileTap: {
      rotateY: 90,
      scale: 0.8,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        duration: 0.5
      }
    },
  }
}

const varHeaderActionButton = ({ palette }) => {
  return {
    initial: false,
    enter: {
      opacity: 1,
      rotateZ: 0,
      transition: {
        staggerChildren: 0.5,
        duration: 0.4,
        when: "afterChildren",
      },
    },
    whileHover: {
      rotateY: 90,
      scale: 1.2,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        duration: 0.5
      }
    },
  }
}

const varHeaderActionButtonSpan = ({ palette }) => {
  return {
    initial: false,
    enter: {
      opacity: 1,
      rotateY: 0,

      transition: {
        staggerChildren: 0.5,
        duration: 1,
        when: "afterChildren",
      },
    },
    whileHover: {
      rotateY: 90,
      scale: 1.2,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        duration: 0.5
      }
    },
  }
}



const varHeaderActionButtonIconPath = (({ palette }) => {
  return {
    initial: false,
    enter: {
      pathLength: 1,
      pathSpacing: 1,
      fillOpacity: 1,
      transition: {
        duration: 0.5,
      }
    },
    whileHover: {
      stroke: palette.primary2.main,
      fill: palette.primary.main,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        duration: 0.5
      }
    },
  }
})


// const varHeaderActionButtonSpanRipple = ({ palette }) => {
//   return {
//     initial: false,
//     enter: {
//       borderRadius: "20%",
//       transition: {
//         staggerChildren: 0.5,
//         duration: 0.4,
//         when: "beforeChildren",
//       },
//     },
//     exit: {
//       scale: 0.5,
//       transition: {
//         duration: 0.1,
//       },
//     },
//     whileHover: {
//       rotateY: 90,
//       scale: 1.2,
//       transition: {
//         repeat: 1,
//         repeatType: "reverse",
//         duration: 0.5
//       }
//     },
//   }
// }

const varMedia = ({ palette }) => {
  return {
    initial: false,
    enter: {
      rotateY: 10,
      transition: {
        staggerChildren: 0.9,
        duration: 0.4,
        when: "beforeChildren",
      },
    },
    whileHover: {
      rotateY: 90,
      scale: 1.2,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        duration: 0.5
      }
    },
    whileTap: {
      rotateY: 90,
      scale: 0.8,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        duration: 0.5
      }
    },
  }
}

const varContent = ({ palette }) => {
  return {
    initial: false,
    enter: {
      x: 0,
      opacity: 1,
      transition: {
        staggerChildren: 0.9,
        duration: 0.5,
        when: "beforeChildren",
      },

    },
    whileHover: {
      rotateY: 90,
      scale: 1.2,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        duration: 0.5
      }
    },
    whileTap: {
      rotateY: 90,
      scale: 0.8,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        duration: 0.5
      }
    },
  }
}

const varContentText = ({ palette }) => {
  return {
    initial: false,
    enter: {
      x: 0,
      transition: {
        duration: 0.4,
        when: "beforeChildren",
      },
    },
    whileHover: {
      rotateY: 90,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        duration: 0.5
      }
    },
    whileTap: {
      rotateY: 90,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        duration: 0.5
      }
    },
  }
}

const varActions = ({ palette }) => {
  return {
    initial: false,
    enter: {
      y: 0,
      transition: {
        staggerChildren: 0.5,
        duration: 0.3,
        when: "beforeChildren",
      },
    },
    whileHover: {
      rotateY: 90,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        duration: 0.5
      }
    },
  }
}

const varActionsButton = ({ palette }) => {
  return {
    initial: false,
    enter: {
      y: 0,
      rotateZ: 0,
      transition: {
        duration: 0.4,
        when: "beforeChildren",
      },
    },
    whileHover: {
      rotateY: 90,
      scale: 1.2,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        duration: 0.5
      }
    },
  }
}

const varActionsButtonSpan = ({ palette }) => {
  return {
    initial: false,
    enter: {
      x: [ -10, 0 ],
      rotateY: 0,
      transition: {
        duration: 0.3,
        when: "beforeChildren",
      },
    },
    whileHover: {
      rotateY: 90,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        duration: 0.5
      }
    },
  }
}

const varActionsButtonSpanPath = (({ palette }) => {
  return {
    initial: false,
    enter: {
      pathLength: 1,
      pathSpacing: 1,
      fillOpacity: 1,
      transition: {
        duration: 0.4,
      }
    },
    whileHover: {
      stroke: palette.primary.main,
      fill: palette.primary.main,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        duration: 0.5
      }
    },
  }
})


const varCollapseRoot = ({ palette, durationCardCollapsing }) => {
  return {
    initial: false,
    expanded: {
      opacity: 1,
      height: "auto",
      transition: {
        duration: durationCardCollapsing,
        when: "afterChildren",
      },
    },
    collapsed: {
      opacity: 0,
      height: 0,
      transition: {
        duration: durationCardCollapsing,
        when: "afterChildren",
      },
    },
    whileHover: {
      rotateY: 90,
      scale: 1.2,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        duration: 0.5
      }
    },
    whileTap: {
      rotateY: 90,
      scale: 0.8,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        duration: 0.5
      }
    },
  }
}

const varCollapseWrap = ({ palette }) => {
  return {
    initial: false,
    expanded: {
      x: 0,
      rotateX: 0,
      transition: {
        duration: 0.4,
        when: "beforeChildren",
      },

    },
    collapsed: {
      rotateX: 45,
      transition: {
        duration: 0.1,
      },
    },
    whileHover: {
      rotateY: 90,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        duration: 0.5
      }
    },
  }
}

const varCollapseWrapInn = ({ palette }) => {
  return {
    initial: false,
    expanded: {
      rotateX: 0,
      transition: {
        duration: 0.4,
        when: "beforeChildren",
      },
    },

    collapsed: {
      rotateX: 45,
      transition: {
        duration: 0.1,
      },
    },
    whileHover: {
      rotateY: 90,
      scale: 1.2,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        duration: 0.5
      }
    },
  }
}

const varCollapseContent = ({ palette }) => {
  return {
    initial: false,
    expanded: {
      y: 0,
      transition: {
        staggerChildren: 0.2,
        duration: 0.4,
        when: "beforeChildren",
      },

    },
    collapsed: {
      y: -20,
      transition: {
        duration: 0.1,
      },
    },
    whileHover: {
      rotateY: 90,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        duration: 0.5
      }
    },
    whileTap: {
      rotateY: 90,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        duration: 0.5
      }
    },
  }
}

const MyCardComplexForwardRef = React.forwardRef(function MyCardComplex(props, ref) {
  const { data, optionsImg, handleExpandedParent, expanded, classNameCollapseRoot, classNameCollapseWrap, classNameCollapseWrapInn, durationCardCollapsing } = props
  const { imgName, imgSrc, itemMaxWidthPx } = optionsImg
  const theme = useTheme()
  const palette = theme.palette
  const { refCard, refCardCollapse } = ref

  const cardStyle = {
    maxWidth: `${itemMaxWidthPx}px`
  }

  if (!data) return (
    <Card
      key={`${imgName}-card`}
      sx={styleCard}
      style={cardStyle}
      raised={true}
      elevation={10}
      variant="elevation"
      ref={refCard}
    >
      <img
        src={imgSrc}
        alt={imgName}
        key={imgName}
      />
      <CardContent>
        <Typography variant="body2" component="p">
          {imgName}
        </Typography>
      </CardContent>
    </Card>
  )
  const { cardHeader, cardMedia, cardContent, collapse } = data
  const handleExpandClick = () => {
    handleExpandedParent()
  };
  const avatarFacesHeader = getFacesAvatars(cardHeader.avatarFaceName, "cardHeader");
  const avatarChildrenHeader = getAvatarsFromChildren(cardHeader.avatarChildren, "cardHeader", palette);
  const avatarFaceCollapse = getFacesAvatars(collapse.avatarFaceName, "cardCollapse");
  const avatarChildrenCollapse = getAvatarsFromChildren(collapse.avatarChildren, "cardCollapse", palette);
  return (
    <StyledMotionDivRoot
      ref={refCard}
      className="MuiCard-root MuiPaper-elevation1 MuiPaper-rounded"
      key={`${imgName}-card`}
      variants={varCard({ palette })}
      initial="initial"
      animate="enter"
      whileHover="whileHover"
      whileTap="whileTap"
    >
      <StyledMotionDivHeader
        className="MuiCardHeader-root"
        variants={varHeader({ palette })}
      >
        <StyledMotionDivSpanAvatars
          className="MuiCardHeader-avatar"
          variants={varSpanAvatars({ palette })}
          style={{ rotateX: 0 }}
        >

          {!!avatarChildrenHeader && (
            avatarChildrenHeader
          )}
          {!!avatarFacesHeader && (
            avatarFacesHeader
          )}
        </StyledMotionDivSpanAvatars>
        <StyledMotionDivHeaderContent
          className="MuiCardHeader-content"
          variants={varHeaderContent({ palette })}
          style={{ y: -150 }}
        >
          <StyledMotionDivHeaderTitle
            className="MuiTypography-root MuiTypography-body2 MuiTypography-displayBlock"
            variants={varHeaderSpanTitle({ palette })}
            style={{ x: -200 }}
          >
            {cardHeader.title}
          </StyledMotionDivHeaderTitle>
          <StyledMotionDivHeaderSubtitle
            className="MuiTypography-root MuiCardHeader-subheader MuiTypography-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock"
            variants={varHeaderSpanSubtitle({ palette })}
            style={{ x: 200 }}
          >
            {cardHeader.subheader}
          </StyledMotionDivHeaderSubtitle>
        </StyledMotionDivHeaderContent>
        <motion.div
          className="MuiCardHeader-action"
          variants={varHeaderAction({ palette })}
          style={{ rotateZ: 180 }}
        >
          <StyledMotionButtonHeaderActionButton
            className="MuiButtonBase-root MuiIconButton-root"
            tabIndex="0"
            type="button"
            aria-label="settings"
            variants={varHeaderActionButton({ palette })}
            style={{ rotateZ: 180, scale: 1 }}
          >
            <StyledMotionSpanHeaderActionButtonSpan
              className="MuiIconButton-label"
              variants={varHeaderActionButtonSpan({ palette })}
              style={{ rotateY: 180, scale: 1 }}
            >
              <svg
                style={styleSvgIconRoot}
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <StyledMotionPathHeaderActionButtonIconPath
                  d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
                  variants={varHeaderActionButtonIconPath({ palette })}
                />
              </svg>
            </StyledMotionSpanHeaderActionButtonSpan>
            <span
              className="MuiTouchRipple-root"
            >
            </span>
          </StyledMotionButtonHeaderActionButton>
        </motion.div>
      </StyledMotionDivHeader>
      <motion.div
        variants={varMedia({ palette })}
        className="MuiCardMedia-root"
        title={cardMedia.title}
        style={{ rotateY: 0, scale: 1, paddingTop: '56.25%', backgroundImage: `url(${imgSrc})` }}
      >
        {cardMedia.title}
      </motion.div>
      <motion.div
        variants={varContent({ palette })}
        className="MuiCardContent-root"
        style={{ x: 500, scale: 1 }}
      >
        <StyledMotionPContentText
          variants={varContentText({ palette })}
          className="MuiTypography-root MuiTypography-body2 MuiTypography-colorTextSecondary"
          style={{ x: -25, }}
        >
          {cardContent.text}
        </StyledMotionPContentText>
      </motion.div>
      <StyledMotionDivActions
        variants={varActions({ palette })}
        className="MuiCardActions-root"
        style={{ y: -20 }}
      >
        <StyledMotionButtonActionsButton
          variants={varActionsButton({ palette })}
          className="MuiButtonBase-root MuiIconButton-root"
          tabIndex="0"
          type="button"
          aria-label="add to favorites"
          style={{ y: -10, rotateZ: 360 }}
        >
          <StyledMotionSpanActionsButtonSpan
            variants={varActionsButtonSpan({ palette })}
            className="MuiIconButton-label"
            style={{ rotateY: 180 }}
          >
            <svg
              style={styleSvgIconRoot}
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <StyledMotionPathActionsButtonSpanPath
                variants={varActionsButtonSpanPath({ palette })}
                d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
              />
            </svg>
          </StyledMotionSpanActionsButtonSpan>
          <span
            className="MuiTouchRipple-root"
          >

          </span>
        </StyledMotionButtonActionsButton>
        <StyledMotionButtonActionsButton
          variants={varActionsButton({ palette })}
          className="MuiButtonBase-root MuiIconButton-root"
          style={{ y: -10, rotateZ: 360 }}
          tabIndex="0"
          type="button"
          aria-label="share"
          key="StyledMotionButtonActionsButton"
        >
          <StyledMotionSpanActionsButtonSpan
            variants={varActionsButtonSpan({ palette })}
            className="MuiIconButton-label"
            style={{ rotateY: 180 }}
            key="StyledMotionSpanActionsButtonSpan"
          >
            <svg
              style={styleSvgIconRoot}

              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <StyledMotionPathActionsButtonSpanPath
                variants={varActionsButtonSpanPath({ palette })}
                d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z"
                key="StyledMotionPathActionsButtonSpanPath1"
              />
            </svg>
          </StyledMotionSpanActionsButtonSpan>
          <span
            className="MuiTouchRipple-root"
          >

          </span>
        </StyledMotionButtonActionsButton>
        <StyledMotionButtonActionsButtonWithExpanded
          variants={varActionsButton({ palette })}
          className="MuiButtonBase-root MuiIconButton-root"
          style={expanded ? { transform: 'rotate(180deg)', y: -10, rotateZ: 360 } : { y: -10, rotateZ: 360 }}
          tabIndex="0"
          type="button"
          aria-expanded={expanded}
          aria-label="show more"
          onClick={() => handleExpandClick()}
          key="StyledMotionButtonActionsButtonWithExpanded"
        >
          <StyledMotionSpanActionsButtonSpan
            variants={varActionsButtonSpan({ palette })}
            className="MuiIconButton-label"
            style={{ rotateY: 180 }}
            key="StyledMotionSpanActionsButtonSpan-y"
          >
            <svg
              style={styleSvgIconRoot}
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <StyledMotionPathActionsButtonSpanPath
                d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"
                variants={varActionsButtonSpanPath({ palette })}
                key="StyledMotionPathActionsButtonSpanPathExp"
              />
            </svg>
          </StyledMotionSpanActionsButtonSpan>
          <span
            className="MuiTouchRipple-root"
          >

          </span>
        </StyledMotionButtonActionsButtonWithExpanded>
      </StyledMotionDivActions>
      <motion.div
        ref={refCardCollapse}
        variants={varCollapseRoot({ palette, durationCardCollapsing })}
        animate={expanded ? "expanded" : "collapsed"}
        className={classNameCollapseRoot}
        style={{ overflow: "hidden", height: "auto", }}
      >
        <motion.div
          className={`MuiCollapse-wrapper ${classNameCollapseWrap}`}
          variants={varCollapseWrap({ palette })}
          style={{
            rotateX: 45
          }}
        >
          <motion.div
            className={`MuiCollapse-wrapperInner ${classNameCollapseWrapInn}`}
            variants={varCollapseWrapInn({ palette })}
            style={{ rotateX: 90 }}
          >
            <motion.div
              className="MuiCardContent-root"
              variants={varCollapseContent({ palette })}
              style={{ y: -20 }}
            >
              {!!collapse.hotelTimeline && <HotelTimeline key="timeline" />}
              {!!collapse.steppersTrip && <SteppersTrip key="trip" />}
              <StyledMotionSpanSpanAvatars
                variants={varSpanAvatars({ palette })}
                animate={expanded ? "enter" : "collapsed"}
              >
                {!!avatarFaceCollapse && avatarFaceCollapse}
                {!!avatarChildrenCollapse && avatarChildrenCollapse}
              </StyledMotionSpanSpanAvatars>
              {collapse.children}
            </motion.div>
          </motion.div>
        </motion.div>
      </motion.div>
    </StyledMotionDivRoot>




  );
})

export default MyCardComplexForwardRef


function getFacesAvatars(avatarsFaces, descr) {
  let avatarFacesFound
  if (avatarsFaces) {
    if (Array.isArray(avatarsFaces)) {
      avatarFacesFound = avatarsFaces.map((afn, i) => {
        return <AvatarFaceByImgName imgName={afn} key={`${afn}-${i}-${descr}-face`} i={i} />;
      });

    } else {
      avatarFacesFound = <AvatarFaceByImgName imgName={avatarsFaces} key={`${avatarsFaces}-${descr}-face`} i={0} />;
    }
  }
  return avatarFacesFound;
}

function getAvatarsFromChildren(avatarsChildren, descr, palette) {
  let avatarChildrenFound
  if (avatarsChildren) {
    if (Array.isArray(avatarsChildren)) {
      avatarChildrenFound = avatarsChildren.map((ach, i) => {
        return (
          <StyledMotionDivAvatar
            className="MuiAvatar-root"
            aria-label="avatChildren"
            key={`${ach}-${i}-${descr}-children`}
            variants={varAvatarCh({ i, palette })}
            style={{ rotateZ: 0 }}
          >
            {ach}
          </StyledMotionDivAvatar>);
      });
    } else {
      avatarChildrenFound = (
        <StyledMotionDivAvatar
          className="MuiAvatar-root"
          aria-label="avatChildren"
          key={`${avatarsChildren}--${descr}-children`}
          variants={varAvatarCh({ i: 0, palette })}
        >
          {avatarsChildren}
        </StyledMotionDivAvatar>);
    }
  }
  return avatarChildrenFound;
}

