import { useStaticQuery, graphql } from "gatsby";

const useHotelImagesW600 = () => {
  const data = useStaticQuery(
    graphql`
      {
 
        hotelImgsFilesW600: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, sourceInstanceName: {eq: "images"}, relativeDirectory: {eq: "galleries/hotel-600"}}) {
          totalCount
          nodes {
            id
            base
            name
            childImageSharp {
              id
              gatsbyImageData
            }
          }
        }
      }
    `
  );
  return data.hotelImgsFilesW600;
};
export default useHotelImagesW600;
